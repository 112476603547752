import {
  reqAllgoods,
  reqAdvanceOrder,
  reqAlipayAndWechatPay,
  reqScIdList,
  reqFindDd,
  scanningOutbound,
  printReceipts,
} from '@/api/cashdesk'

const state = {
  // 所有商品的数据
  allGoodsDatas: [],
  totalCount: 0,
  totalPage: 0,
  isAlipay:false,
  // 仓库id
  scIdList: [],
  // 仓库第一个id
  scIdone: '',
  // 订单编号
  ordersn: '',
  orderid: '',
  // 支付成功和失败的标识
  payCode: 0,
  msg: '',
  // 条形码的商品
  upcCodeData: {},
  // 查单的code
  findDDCode: '',
  // 聚合支付的code码
  scanningOutboundCode: '',
  // 聚合支付 msg
  // scanningOutbound
  boundMsg: '',
  // 预订单code
  advacneCode: '',
  advanceMsg: '',
}

const mutations = {
  SET_ALLGOODSDATAS(state, allGoodsDatas) {
    state.allGoodsDatas = allGoodsDatas
  },
  SET_TOTALCOUNT(state, totalCount) {
    state.totalCount = totalCount
  },
  SET_TOTALPAGE(state, totalPage) {
    state.totalPage = totalPage
  },
  SET_SCIDLIST(state, scIdList) {
    state.scIdList = scIdList
  },
  SET_ORDERSN(state, ordersn) {
    state.ordersn = ordersn
  },
  // orderid
  SET_ORDERID(state, orderid) {
    state.orderid = orderid
  },
  SET_PAYCODE(state, payCode) {
    state.payCode = payCode
  },
  SET_PAYMSG(state, msg) {
    state.msg = msg
  },
  SET_UPCCODE(state, upcCodeData) {
    state.upcCodeData = upcCodeData
  },
  SET_FINDDDCODE(state, findDDCode) {
    state.findDDCode = findDDCode
  },
  SER_SCIDONE(state, scIdone) {
    state.scIdone = scIdone
  },
  // scanningOutboundCode
  SET_SCANNINGOUTBOUNDCODE(state, scanningOutboundCode) {
    state.scanningOutboundCode = scanningOutboundCode
  },
  // 聚合支付 msg
  // boundMsg
  SET_BOUNDMSG(state, boundMsg) {
    state.boundMsg = boundMsg
  },
  // advacneCode: '',
  SET_ADVANCECODE(state, advacneCode) {
    state.advacneCode = advacneCode
  },
  // advanceMsg
  SET_ADVANCEMSG(state, advanceMsg) {
    state.advanceMsg = advanceMsg
  },
  SET_ALIPAY(state, isAlipay) {
    state.isAlipay = isAlipay
  },
}

const actions = {
  // 请求仓库id的接口
  async reqScIdList({ commit, state, dispatch }) {
    const result = await reqScIdList()
    if (result.code === 200) {
      commit('SET_SCIDLIST', result.data.datas)
      commit('SER_SCIDONE', result.data.datas[0].id)
      return 'ok'
    } else {
      return Promise.reject(new Error(result.message))
    }
  },
  // 请求所有商品的接口
  async reqAllgoods({ commit, state, dispatch }, data) {
    const result = await reqAllgoods(data)
    if (result.code === 200) {
      commit('SET_ALLGOODSDATAS', result.data.datas)
      commit('SET_TOTALCOUNT', result.data.totalCount)
      commit('SET_TOTALPAGE', result.data.totalPage)
      return 'ok'
    } else {
      return Promise.reject(new Error(result.message))
    }
  },
  // 请求条形码的商品的接口
  async reqCodegoods({ commit, state, dispatch }, data) {
    const result = await reqAllgoods(data)
    if (result.code === 200) {
      commit('SET_UPCCODE', result.data.datas[0])
    }
  },

  // 请求预订单接口
  async reqAdvanceOrder({ commit, state, dispatch }, data) {
    const result = await reqAdvanceOrder(data)
      commit('SET_ADVANCECODE', result.code)
      commit('SET_ALIPAY', result.data.is_alipay)
      commit('SET_ADVANCEMSG', result.msg)
      commit('SET_ORDERSN', result.data.ordersn)
      commit('SET_ORDERID', result.data.orderid)
      return 'ok'
  },
  // 反扫支付宝和微信支付接口
  async reqAlipayAndWechatPay({ commit, state, dispatch }, data) {
    const result = await reqAlipayAndWechatPay(data)
    commit('SET_PAYCODE', result.code)
    commit('SET_PAYMSG', result.message)
  },
  // 调订单同步接口
  async reqFindDd({ commit, state, dispatch }, data) {
    const result = await reqFindDd(data)
    commit('SET_FINDDDCODE', result.code)
  },
  // 聚合支付
  async scanningOutbound({ commit, state, dispatch }, data) {
    const result = await scanningOutbound(data)
    commit('SET_SCANNINGOUTBOUNDCODE', result.code)
    commit('SET_BOUNDMSG', result.msg)
  },
  // 打印小票
  async printReceipts({ commit, state, dispatch }, data) {
    const result = await printReceipts(data)
    console.log(result, 'result')
  },
}

const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
